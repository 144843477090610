<template>
  <q-page class="q-pa-sm">
    <div class="row full-width">
      <q-table
          v-model:pagination="pagination"
          :columns="columns"
          :loading="loading"
          :rows="rows"
          :rows-per-page-options="[3, 10, 50, 100, 1000]"

          class="full-width"
          flat
          row-key="article_id"
          title="Новости"
          @request="onRequest"
      >
        <template v-slot:top-right>
          <q-btn :to="$route.fullPath + '/addedit'" color="accent" icon="add"
                 label="Добавить"
                 size="sm"/>
        </template>
        <template v-slot:body-cell-Action="props">
          <q-td :props="props" class="text-left">

            <q-btn v-if="props.row.status === 'draft'"
                   :to="$route.fullPath + '/addedit/' + props.row.article_id"
                   dense
                   icon="edit"
                   round
                   size="sm">
              <q-tooltip>Редактировать</q-tooltip>
            </q-btn>

            <q-btn v-if="props.row.status === 'draft'"
                   class="q-ml-sm"
                   color="accent"
                   dense
                   icon="publish"
                   round
                   size="sm"
                   @click="() => publish(props.row.article_id)"
            >
              <q-tooltip>Опубликовать</q-tooltip>
            </q-btn>

            <q-btn v-if="props.row.status === 'published'"
                   class="q-ml-sm"
                   dense
                   icon="archive"
                   round
                   size="sm"
                   @click="() => archive(props.row.article_id)"
            >
              <q-tooltip>В архив</q-tooltip>
            </q-btn>
          </q-td>
        </template>

      </q-table>
    </div>
  </q-page>
</template>

<script>
import { NewsApi } from '@/api/apis/news-api.ts';
import { toHumanDateTime } from '@/helpers/filters/toHumanDate';
import { Notify } from 'quasar';
import { defineComponent, onMounted, ref } from 'vue';

const statusOptionLabels = {
  draft: 'Черновик',
  published: 'Опубликовано',
  archived: 'В архиве',
};

const columns = [
  {
    field: 'title',
    label: 'Заголовок',
    align: 'left',
    style: 'width: 300px; white-space: normal;',
    // classes: 'ellipsis',
  },
  {
    name: 'created_at',
    field: 'created_at',
    align: 'center',
    label: 'Дата создания',
    sortable: true,
    format: toHumanDateTime,
  },
  {
    name: 'updated_at',
    field: 'updated_at',
    align: 'center',
    label: 'Дата обновления',
    sortable: true,
    format: toHumanDateTime,
  },
  {
    field: 'status',
    align: 'center',
    label: 'Статус',
    format: (v) => statusOptionLabels[v],
  },
  {
    name: 'Action',
    label: '',
    field: 'Action',
    sortable: false,
    align: 'left',
  },
];

export default defineComponent({

  components: {},

  setup() {
    const rows = ref([]);

    const loading = ref(false);
    const pagination = ref({
      sortBy: 'updated_at',
      descending: true,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 10,
    });

    async function onRequest(props) {
      props = props || {
        pagination: pagination.value,
      };

      const {
        page,
        rowsPerPage,
        sortBy,
        descending,
      } = props.pagination;

      // debugger;

      loading.value = true;

      const response = await new NewsApi().newsControllerFindArticles(
        true,
        page,
        rowsPerPage,
        descending ? 'DESC' : 'ASC',
        undefined,
        undefined,
        undefined,
        sortBy || undefined,
      );
      const { data } = response.data;
      pagination.value.rowsNumber = response.data.total_count;
      rows.value.splice(0, rows.value.length, ...data);

      pagination.value.page = page;
      pagination.value.rowsPerPage = rowsPerPage;
      pagination.value.sortBy = sortBy;
      pagination.value.descending = descending;

      loading.value = false;
    }

    onMounted(() => {
      // get initial data from server (1st page)
      onRequest();
    });

    return {
      loading,
      pagination,
      columns,
      rows,
      console,
      onRequest,

      async publish(id) {
        try {
          await new NewsApi().newsControllerPublishArticleRevision(id);
          Notify.create('Опубликовано');

          await onRequest();
        } catch (e) {
          console.error(e);
        }
      },

      async archive(id) {
        try {
          await new NewsApi().newsControllerArchiveArticleRevision(id);
          Notify.create('Статус изменен: архив');

          await onRequest();
        } catch (e) {
          console.error(e);
        }
      },
    };
  },
});
</script>

<style>

</style>
